import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import { graphql } from "gatsby";
import { MainContainer, ContentDiv } from "../styles/markdown.styles";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Seo title={frontmatter.title} pathname={frontmatter.slug} />
      <MainContainer className="section-wrapper">
        <ContentDiv className="layout-width">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </ContentDiv>
      </MainContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
