import styled from "@emotion/styled";

const MainContainer = styled.div`
  width: -webkit-fill-available;
  height: auto;

  display: flex;
  justify-content: space-between;
`;

const ContentDiv = styled.div``;

export { MainContainer, ContentDiv };
